<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="my-aq-list" style="min-height: 100vh;" ref="listView">
			<div class="aq-item" v-for="(item, index) in dataList" :key="index">
				<div class="row">
					<span class="gender" v-text="item.gender == 1 ? '男' : '女'"></span>
					<span class="age" v-text="item.age+'歲'"></span>
					<span class="create-time" v-text="item.createtime"></span>
				</div>
				<div class="title-w">
					<span class="category"><router-link :to="'/ask/list?IllCate='+item.illnesscategoryid" class="q-category" v-text="'#'+item.illnesscategorycode"></router-link></span>
					<h2 class="title"><router-link :to="'/ask/detail/'+item.askid" v-text="item.title"></router-link></h2>
				</div>
				<p class="content" v-text="item.note"></p>
				<div class="sub-question" v-if="item.subAskList.length">
					<span class="hint">追問:</span>
					<div class="sub-question-content" v-text="item.subAskList[0].note"></div>
				</div>
				<div :class="{'answer-list': true, 'hide': askidList.indexOf(item.askid) == -1}">
					<div class="answer-item" v-for="(answer, i) in item.answerList" :key="index +'-'+ i">
						<div class="row">
							<img src="../../../assets/imgs/mobile/user_center/img_nvyis@2x.png" alt="" class="icon-doctor">
							<span class="doctor-name" v-text="'醫師'+answer.doctorname"></span>
							<span class="answer-time" v-text="answer.createdtime"></span>
						</div>
						<p class="answer-content" v-text="answer.note"></p>
					</div>
				</div>
				<div class="operate">
					<div :class="{'btn-expand': true, 'hide': askidList.indexOf(item.askid) != -1}">
						<img src="../../../assets/imgs/mobile/user_center/icon_xiaoxi@2x.png" alt="" class="icon-answer">
						<router-link class="hint" :to="'/ask/detail/'+item.askid" v-text="item.answerList.length + '條回復'"></router-link>
					</div>
					<!-- <div @click="extrendAnswer(item)" :class="{'btn-collapse': true, 'hide': askidList.indexOf(item.askid) == -1}">收起回復</div>
					<div @click="item.subAskList.length == 0 && preAddSubAsk(item)" :class="{'btn-sub-question': true, 'disable': item.subAskList.length > 0}">
						<span class="icon-sub-question"></span>
						<span class="hint">追問</span>
					</div> -->
				</div>
			</div>
			<div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
		<div class="modal-sub-ask" v-if="show">
			<div class="modal-content">
				<textarea cols="30" rows="3" class="content" v-model="subContent" placeholder="請輸入追問內容"></textarea>
				<div class="row">
					<div class="btn-cancel" @click="onCancel">取消</div>
					<div class="btn-confirm" @click="onConfirm"><div class="lds-ring" v-if="showAddSubLoading"><div></div><div></div><div></div><div></div></div>{{showAddSubLoading ? '正在提交...' : '确定'}}</div>
				</div>
			</div>
		</div>
	</van-pull-refresh>
</template>

<script>
	import my_ask from '@/views/mixins/my_ask'
	import Loading from '@/layouts/Loading.vue'
	export default {
		mixins: [
			my_ask,
		],
		components: {
			Loading
		},
		data() {
			return {
				showAddSubLoading: false,
				show: false,
				askData: null,
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				clientId: this.$store.getters.clientId,
				dataList: [],
				loadMore: false,
				askidList: [],
				style: '',
				subContent: '',
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData({
					clientid: this.clientId, 
					pageNo: this.pageNo, 
					pageSize: this.pageSize
				}, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
				})
			},
			preAddSubAsk: function(askData){
				this.askData = askData
				this.style = document.body.getAttribute('style') || ''
				document.body.setAttribute('style', this.style + 'overflow: hidden;')
				this.show = true
			},
			onCancel: function(){
				this.show = false
				document.body.setAttribute('style', this.style)
			},
			async onConfirm(){
				//添加追问
				if(this.showAddSubLoading) return
				if(!this.subContent.trim()) {
					this._toast.warning(this, '請輸入追問內容')
					return
				}
				this.showAddSubLoading = true
				try {
					var rs = await this.$store.dispatch('addQuestion', {
						ip: '',
						title: this.askData.title,
						askid: this.askData.askid,
						createdby: this.$store.getters.clientId,
						note: this.subContent.trim(),
					})
					this._toast.success(this, '添加追問成功')
					this.showAddSubLoading = false
					this.show = false
					document.body.setAttribute('style', this.style)

					this.dataList.find(d=>d.askid == this.askData.askid).subAskList= [{'note': this.subContent.trim()}]
					this.dataList = [...this.dataList]
				} catch (error) {
					this._toast.warning(this, error || '數據加載失敗...')
					this.showAddSubLoading = false
				}
			},
			extrendAnswer(askData){
                if(! askData.answerList.length) return
                if(this.askidList.indexOf(askData.askid) == -1) {
                    this.askidList.push(askData.askid)
                } else {
                    this.askidList.splice(this.askidList.indexOf(askData.askid), 1)
                }
			},
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({
							clientid: this.clientId, 
							pageNo: this.pageNo, 
							pageSize: this.pageSize
						}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.my-aq-list {
		display: flex;
		flex-direction: column;
	}

	.my-aq-list .aq-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin: 0 24px 16px 24px;
		padding: 31px 32px 40px 32px;
	}

	.my-aq-list .aq-item .row {
		display: flex;
		flex-direction: row;
	}

	.my-aq-list .aq-item .title-w {
		margin-top: 18px;
		margin-bottom: 21px;
	}

	.my-aq-list .aq-item .title-w .category {
		display: inline-block;
		color: #FF8000;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
		margin-right: 12px;
	}

	.my-aq-list .aq-item .title-w .title{
		display: inline;
		color: #231F20;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
	}

	.my-aq-list .aq-item .gender,
	.my-aq-list .aq-item .age,
	.my-aq-list .aq-item .create-time {
		color: #969696;
		font-size: 26px;
		line-height: 25px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .gender {
		margin-right: 8px;
	}

	.my-aq-list .aq-item .age {
		margin-right: 28px;
	}

	.my-aq-list .aq-item .content {
		line-height: 39px;
		color: #696969;
		font-weight: 400;
		font-size: 28px;
	}

	.my-aq-list .aq-item .answer-list {
		background-color: #EFF4F9;
		margin: 30px -17px 0px -17px;
		padding-bottom: 30px;
	}

	.my-aq-list .aq-item .answer-list.hide {
		display: none;
	}

	.my-aq-list .aq-item .answer-list > .answer-item {
		display: flex;
		flex-direction: column;
		padding: 30px 16px 0px 16px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .icon-doctor {
		width: 32px;
		height: 32px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .doctor-name {
		margin-left: 15px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-time {
		margin-left: 19px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-content {
		margin-left: 47px;
		color: #231F20;
		font-size: 28px;
		font-weight: 400;
		margin-top: 6px;
	}

	.my-aq-list .aq-item .btn-collapse {
		color: #36C4D0;
		font-weight: 400;
		font-size: 26px;
	}

	.my-aq-list .aq-item .btn-collapse.hide {
		display: none;
	}

	.my-aq-list .aq-item .btn-expand {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	.my-aq-list .aq-item .btn-expand.hide {
		display: none;
	}

	.my-aq-list .aq-item .btn-expand > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
		line-height: 25px;
		display: inline-block;
		height: 25px;
		padding-top: 2px;
	}

	.my-aq-list .aq-item .btn-expand > .icon-answer {
		width: 25px;
		height: 23px;
		margin-right: 23px;
	}

	.my-aq-list .aq-item .sub-question {
		font-size: 28px;
		color: #231F20;
		font-weight: bold;
		line-height: 32px;
		margin-top: 21px;
		position: relative;
	}

	.my-aq-list .aq-item .sub-question > .hint {
		color: #36C4D0;
		margin-right: 12px;
		position: absolute;
		left: 0;
		top: 0;
		display: block;
	}

	.my-aq-list .aq-item .sub-question > .sub-question-content::first-letter {
		padding-left: 72px;
	}

	.my-aq-list .aq-item .operate {
		display: flex;
		flex-direction: row;
		margin-top: 30px;
	}

	.my-aq-list .aq-item .btn-sub-question {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: 32px;
		align-items: center;
		margin-left: 93px;
	}

	.my-aq-list .aq-item .btn-sub-question > .icon-sub-question {
		width: 24px;
		height: 22px;
		margin-right: 16px;
		vertical-align: middle;
		background-image: url(../../../assets/imgs/mobile/user_center/icon_zhuiwen@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.my-aq-list .aq-item .btn-sub-question.disable > .icon-sub-question {
		background-image: url(../../../assets/imgs/mobile/user_center/img_zhuiwenblock@2x.png);
	}

	.my-aq-list .aq-item .btn-sub-question > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .btn-sub-question.disable > .hint {
		color: #BFBFBF;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.modal-sub-ask {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		background-color: rgba(0, 0, 0, .2);
	}

	.modal-content {
		width: 72%;
		height: 300px;
		padding: 24px;
		box-sizing: border-box;
		border-radius: 24px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
	}

	.modal-content > .row {
		height: 64px;
		margin-top: 20px;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	
	.modal-content > .row > .btn-confirm,
	.modal-content > .row > .btn-cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		border-radius: 12px;
		border: 1px solid #e7e7e7;
		background-color: #fff;
		font-size: 28px;
		font-weight: bold;
		color: #999;
		overflow: hidden;
	}

	.modal-content > .row > .btn-confirm {
		margin-left: 24px;
		color: #666;
	}

	.modal-content > .row > .btn-confirm:active,
	.modal-content > .row > .btn-cancel:active {
		opacity: 0.7;
	}

	.modal-content > textarea {
		width: 100%;
		flex: 1;
		background-color: #f0f0f0;
		padding: 12px;
		box-sizing: border-box;
		font-size: 32px;
		font-weight: bold;
		color: #231F20;
	}

	.modal-content > textarea::placeholder {
		font-weight: 400;
		font-size: 28px;
		color: #999;
	}

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #eee;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #eee transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 
</style>
